import React, { ForwardedRef } from 'react';

import { translate } from '@dop/shared/translate/translate';
import { IconSluiten } from '@dop/ui-icons/svg/sluiten';
import {
	ButtonBlock,
	ButtonBlockProps,
} from '@dop/ui-primitives/interaction/InteractionBlock';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { Box } from '@dop/ui-primitives/planeDivision/Box';

/**
 * Big chunky close-button, used for SectorInfoPopup or SideOverlay. Takes any button props, like `onClick`.
 * The button has a predefined height/width, so when used in a grid (e.q. for a slot-layout) you can set the column/row size to `auto`
 */
export const CloseButton = React.forwardRef(
	(props: ButtonBlockProps, ref: ForwardedRef<HTMLButtonElement>) => {
		return (
			<ButtonBlock
				$gridArea={'close'}
				$alignSelf="start"
				aria-label={translate('close')}
				{...props}
				ref={ref}
			>
				<Box
					$backgroundColor={['positive', 'normal']}
					$padding={1.125}
					$inlineSize={3.6}
					$blockSize={3.6}
				>
					<Svg
						as={IconSluiten}
						$inlineSize={'100%'}
						$blockSize={'100%'}
						$fill={['negative', 'normal']}
					/>
				</Box>
			</ButtonBlock>
		);
	},
);
