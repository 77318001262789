const setTimeToZero = (date: Date) => {
	const newDate = new Date(date);
	newDate.setHours(0, 0, 0, 0);

	return newDate;
};

export const isSameDay = (dateTime1: Date, dateTime2: Date) => {
	const date1 = setTimeToZero(dateTime1);
	const date2 = setTimeToZero(dateTime2);

	return date1.getTime() === date2.getTime();
};

export const newDate = (dateString: string | undefined) => {
	if (!dateString) return undefined;

	const date = new Date(dateString);

	if (date.toString() === 'Invalid') return undefined;

	return date;
};

const normalizeDateString = (dateString: string) => {
	return (
		dateString
			// Remove dots from months
			.replace(/\b([a-zA-Z]{3})\./g, '$1')
			// Normalize kind of spaces and dashes
			// This occurs in date ranges
			.replaceAll(' - ', ' – ')
	);
};

/**
 * Normalize differences in date time formatting
 * between NodeJS and browsers.
 *
 * The Intl.DateTimeFormat object is proxied so that
 * we can intervene any format call to normalize the
 * result while keeping the usage exactly the same
 * as the official Intl.DateTimeFormat object.
 *
 * More info on proxies: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
 */
export const IntlDateTimeFormat = new Proxy(Intl.DateTimeFormat, {
	apply: (...args) => {
		return new Proxy(Reflect.apply(...args), {
			get: (formatterTarget, prop) => {
				const targetProp = Reflect.get(formatterTarget, prop);

				if (typeof targetProp !== 'function') return targetProp;

				return new Proxy(targetProp, {
					apply: (target, _thisArg, argumentsList) => {
						const result = Reflect.apply(
							target,
							formatterTarget,
							argumentsList,
						);

						if (typeof result !== 'string') return result;

						return normalizeDateString(result);
					},
				});
			},
		});
	},
});
