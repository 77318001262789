import { getDocument } from '@dop/shared/helpers/windowDocument';

const doc = getDocument();
/**
 * This function is used to get client-id of the google, but not through the 'tracker' object, instead through the cookie
 * This is due to the fact, that javascript of the analytics can be disabled.
 * taken from: https://madoveranalytics.com/featured/cookies/759/
 */
const cookieRe = /_ga=([^;$]+)/;
export const getFeedbackAnalyticsClientID = () => {
	let time;
	let random;

	const match = doc ? doc.cookie.match(cookieRe) : null;
	if (match) {
		const parts = match[1].split(/\./g);
		time = parts.pop();
		random = parts.pop();
	} else {
		// no cookie is there, generate something
		random = Math.floor(Math.random() * 1000000000);
		time = Math.floor(new Date().getTime() / 1000);
	}

	return `${random}.${time}`;
};
