import { config } from 'moduleAlias/config';

import { IntlDateTimeFormat, isSameDay } from './dateTimeHelpers';

const { locale, timeZone } = config.localization;

/**
 * @example 2
 */
export const dayNumericFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	day: 'numeric',
});
/**
 * @example dec
 */
export const monthShortFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	month: 'short',
});

/**
 * @example 2 december
 */
export const monthDayFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	month: 'long',
	day: 'numeric',
});
/**
 * @example 02-12-2022
 */
export const dateShortFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	dateStyle: 'short',
});
/**
 * @example 2 dec 2022
 */
export const dateMediumFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	dateStyle: 'medium',
});
/**
 * @example 2 december 2022
 */
export const dateLongFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	dateStyle: 'long',
});
/**
 * @example vr 2 dec 2022
 */
export const dateWithWeekdayFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	weekday: 'short',
});
/**
 * @example vr 2 dec 2022 21:24
 */
export const dateTimeWithWeekdayFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	weekday: 'short',
	hour: 'numeric',
	minute: '2-digit',
});
/**
 * @example 21:24
 */
export const timeShortFormatter = IntlDateTimeFormat(locale, {
	timeZone,
	hour: 'numeric',
	minute: '2-digit',
});

export const formatDateRangeWithTimeRange = (
	fromDateTime: Date | undefined,
	untilDateTime: Date | undefined,
):
	| []
	| [formattedDateTime: string]
	| [formattedDateTimeRange: string]
	| [formattedDate: string, formattedTime: string] => {
	if (fromDateTime == null) return [];

	if (untilDateTime == null) {
		return [dateTimeWithWeekdayFormatter.format(fromDateTime)];
	}

	if (isSameDay(fromDateTime, untilDateTime)) {
		return [
			dateWithWeekdayFormatter.format(fromDateTime),
			timeShortFormatter.formatRange(fromDateTime, untilDateTime),
		];
	}

	return [
		dateTimeWithWeekdayFormatter.formatRange(fromDateTime, untilDateTime),
	];
};
