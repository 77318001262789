'use client';

import { classes } from '@/uiPrimitives/base/classes.helpers';
import { stack } from '@/uiPrimitives/layout/stack';
import { hidden } from '@/uiPrimitives/utility/display.css';
import { ReactNode, useRef, useState } from 'react';
import { submitPageFeedback } from './PageFeedback.server';
import { box } from '@/uiPrimitives/layout/box';
import { textStyle } from '@/uiPrimitives/typography/text';
import { v4 as createUID } from 'uuid';
import { Site } from '@/globals/webApiTypes/site.types';
import { SubmitButton } from './SubmitButton';
import { useFormState } from 'react-dom';
import { useAnalyticsPageMeta } from '@/app/api/push-event/_helpers/useAnalyticsPageMeta.client';
import { usePathname } from 'next/navigation';
import { usePageFeedbackPopup } from './usePageFeedbackPopup';
import { disclaimerCSS } from './PageFeedback.css';
import { position } from '@/uiPrimitives/layout/position';
import {
	pushFieldChangedEvent,
	pushSubmittedEvent,
} from '@/globals/analytics/events/pushFeedbackEvent';
import { getFeedbackAnalyticsClientID } from '@/sections/pageFeedback/getFeedbackAnalyticsClientID';
import { useGoogleReCaptcha } from '@dop/shared/components/GoogleReCaptcha/GoogleReCaptchaProvider';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';
import { pageGridItem } from '@/uiPrimitives/layout/pageGrid/pageGridItem';

const badRatings = ['1', '2'];
const goodRatings = ['3', '4', '5'];

const getExplanationQuestion = ({
	explanationQuestionSlots,
	rating,
	improvement,
}: {
	explanationQuestionSlots: Record<string, ReactNode>;
	rating: string | undefined;
	improvement: string | undefined;
}) => {
	if (rating == null) {
		return undefined;
	}

	if (goodRatings.includes(rating)) {
		return explanationQuestionSlots.good;
	}

	if (improvement == null) {
		return undefined;
	}

	return explanationQuestionSlots[improvement];
};

// eslint-disable-next-line complexity
export const PageFeedbackForm = ({
	ratingSlot,
	improvementSlot,
	explanationQuestionSlotRecord,
	explanationFieldSlot,
	disclaimerSlot,
	submitButtonText,
	submitMessages,
	thankYouSlot,
	thankYouSDGSlot,
}: {
	ratingSlot: ReactNode;
	improvementSlot: ReactNode;
	explanationQuestionSlotRecord: Record<string, ReactNode>;
	explanationFieldSlot: ReactNode;
	disclaimerSlot: ReactNode;
	thankYouSlot: ReactNode;
	thankYouSDGSlot: ReactNode;
	submitButtonText: string;
	submitMessages: Site['pageFeedback']['submitMessages'];
}) => {
	const [rating, setRating] = useState<string | undefined>(undefined);
	const [improvement, setImprovement] = useState<string | undefined>(undefined);

	const pageAnalyticsMeta = useAnalyticsPageMeta();
	delete pageAnalyticsMeta.wordCount;
	delete pageAnalyticsMeta.type;
	delete pageAnalyticsMeta.opContentType;
	delete pageAnalyticsMeta.opPageType;
	delete pageAnalyticsMeta.opRegio;
	delete pageAnalyticsMeta.opOnderwerp;
	delete pageAnalyticsMeta.opBranche;
	delete pageAnalyticsMeta.lastChecked;

	const pathname = usePathname();
	const token = useRef<string>(createUID());
	const executeRecaptcha = useGoogleReCaptcha();
	const valuesRef = useRef<Map<string, string>>(new Map());

	const { isPopping, onFieldChange, onSubmit } = usePageFeedbackPopup();

	const [state, formAction] = useFormState(
		async (state: 'idle' | 'fulfilled' | 'rejected', formData: FormData) => {
			if (state === 'fulfilled') {
				return state;
			}

			onSubmit();
			const recaptchaResponse = await executeRecaptcha();
			const clientID = getFeedbackAnalyticsClientID();

			if (rating == null || !badRatings.includes(rating))
				formData.delete('improvement');

			try {
				const newState = await submitPageFeedback({
					formData,
					pageMeta: pageAnalyticsMeta,
					recaptchaResponse,
					token: token.current,
					pathname,
					feedbackFinished: true,
					clientID,
				});

				if (newState === 'fulfilled') {
					const ratingValue = formData.get('rating');
					pushSubmittedEvent({
						ratingValue: typeof ratingValue === 'string' ? ratingValue : '',
						isPopping,
					});
				}
				return newState;
			} catch (error) {
				return 'rejected';
			}
		},
		'idle',
	);

	if (state === 'fulfilled') {
		const isSDGPage = pageAnalyticsMeta.sdg?.pageTagged ?? false;

		return (
			<div
				className={classes(
					pageGrid({}),
					textStyle({
						fontVariantNumeric: 'lining-nums',
						capLineHeight: 'tight',
					}),
					box({
						backgroundColor: 'negativeBlue',
						paddingBlock: '3 | h1',
					}),
					position({ position: 'relative' }),
				)}
			>
				<div
					className={classes(
						pageGridItem({ gridColumn: 'start-8-of-12' }),
						stack({ gap: '3 | h1' }),
					)}
				>
					{thankYouSlot}
					{isSDGPage && thankYouSDGSlot}
				</div>
			</div>
		);
	}

	const explanationQuestion = getExplanationQuestion({
		explanationQuestionSlots: explanationQuestionSlotRecord,
		rating,
		improvement,
	});
	return (
		<div
			className={classes(
				pageGrid({}),
				textStyle({
					fontVariantNumeric: 'lining-nums',
					capLineHeight: 'tight',
				}),
				position({ position: 'relative' }),
			)}
		>
			<div
				className={classes(
					pageGrid({}),
					box({
						backgroundColor: 'negativeBlue',
					}),
				)}
			>
				<form
					className={classes(
						pageGridItem({ gridColumn: 'start-8-of-12' }),
						stack({ gap: '7 | betweenH3Sections' }),

						box({
							paddingBlockStart: '3 | h1',
							paddingBlockEnd:
								explanationQuestion == null ? '3 | h1' : undefined,
						}),
					)}
					action={formAction}
					onChange={async (event) => {
						if (
							!(event.target instanceof HTMLInputElement) &&
							!(event.target instanceof HTMLTextAreaElement)
						) {
							return;
						}
						onFieldChange();
						const oldValue =
							valuesRef.current.get(event.target.name) ??
							event.target.defaultValue;
						pushFieldChangedEvent({
							name: event.target.name,
							value: event.target.value,
							isPopping,
							oldValue,
						});
						valuesRef.current.set(event.target.name, event.target.value);
						if (event.target.name === 'rating') {
							setRating(event.target.value);
							const formData = new FormData(event.target.form ?? undefined);
							const clientID = getFeedbackAnalyticsClientID();

							const recaptchaResponse = await executeRecaptcha();
							await submitPageFeedback({
								formData,
								pageMeta: pageAnalyticsMeta,
								recaptchaResponse,
								token: token.current,
								clientID,
								pathname,
								feedbackFinished: false,
							});
							return;
						}

						if (event.target.name === 'improvement') {
							setImprovement(event.target.value);
							return;
						}
					}}
					{...addTestSelector('uiFeedbackContainer')}
				>
					{ratingSlot}
					<div
						className={classes(
							(rating == null || !badRatings.includes(rating)) && hidden,
						)}
					>
						{improvementSlot}
					</div>
					<div
						className={classes(
							stack({
								gap: '3 | h1',
							}),
							explanationQuestion == null && hidden,
						)}
					>
						{explanationQuestion}
						{explanationFieldSlot}
					</div>
					{explanationQuestion != null && (
						<SubmitButton
							text={submitButtonText}
							loadingText={submitMessages.loadingMessage}
							errorText={submitMessages.errorMessage}
							isRejected={state === 'rejected'}
						/>
					)}
				</form>
			</div>
			{explanationQuestion != null && (
				<div
					className={classes(
						pageGrid({}),
						disclaimerCSS,
						box({
							backgroundColor: 'negative',
							paddingBlock: '0 | p',
						}),
					)}
				>
					<div
						className={classes(pageGridItem({ gridColumn: 'start-8-of-12' }))}
					>
						{disclaimerSlot}
					</div>
				</div>
			)}
		</div>
	);
};
