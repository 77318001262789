import React from 'react';

import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import {
	TextBlock,
	TextBlockProps,
} from '@dop/ui-primitives/typography/TextBlock';
import { Svg, SvgProps } from '@dop/ui-primitives/media/Svg';
import { Box, BoxProps } from '@dop/ui-primitives/planeDivision/Box';
import {
	ButtonBlock,
	ButtonBlockProps,
} from '@dop/ui-primitives/interaction/InteractionBlock';

import { LinkBlock, LinkBlockProps } from './LinkBlock';
import { WithLinkTypeIcon } from './WithLinkTypeIcon';

export const OutlinedBoxIcon = (props: SvgProps) => {
	return (
		<Svg
			$inlineSize={'auto'}
			$fill={['positive', 'normal']}
			$blockSize={1}
			{...props}
		/>
	);
};

export const OutlinedBoxText = ({
	children,
	href,
	...props
}: TextBlockProps & { href?: string }): React.ReactElement | null => {
	return (
		<TextBlock $fontWeight={'bold'} {...props}>
			<WithLinkTypeIcon href={href}>{children}</WithLinkTypeIcon>
		</TextBlock>
	);
};

const OutlinedBox = ({
	children,
	disabled,
	...props
}: BoxProps & { disabled?: boolean }): React.ReactElement | null => {
	return (
		<Box
			$padding={[1.5, 2]}
			$border={[
				'2px',
				'solid',
				disabled ? ['positive', 'muted'] : ['positive', 'normal'],
			]}
			$hover={{
				$backgroundColor: disabled ? undefined : ['negative', 'secondary'],
			}}
			$minInlineSize="max-content"
			{...props}
		>
			<Shelf $gap={1} $alignItems="start">
				{children}
			</Shelf>
		</Box>
	);
};

/**
 * A link that looks like a button, with a shelf to allow for nice placement of an icon and text.
 * Use with OutlinedBoxText and OutlinedBoxIcon as the children.
 */
export const OutlinedBoxLink = React.forwardRef(
	(
		{
			children,
			disabled,
			boxProps,
			...props
		}: LinkBlockProps & {
			disabled?: boolean;
			boxProps?: BoxProps;
		},
		ref: React.ForwardedRef<HTMLAnchorElement>,
	): React.ReactElement | null => {
		return (
			<LinkBlock ref={ref} {...props} $inlineSize="fit-content">
				<OutlinedBox disabled={disabled} {...boxProps}>
					{children}
				</OutlinedBox>
			</LinkBlock>
		);
	},
);

export type OutlinedBoxLinkProps = React.ComponentPropsWithRef<
	typeof OutlinedBoxLink
>;

/**
 * A button with a shelf to allow for nice placement of an icon and text.
 * Use with OutlinedBoxText and OutlinedBoxIcon as the children.
 */
export const OutlinedBoxButton = React.forwardRef(
	(
		{
			children,
			disabled,
			boxProps,
			...props
		}: ButtonBlockProps & {
			disabled?: boolean;
			boxProps?: BoxProps;
		},
		ref: React.ForwardedRef<HTMLButtonElement>,
	): React.ReactElement | null => {
		return (
			<ButtonBlock
				ref={ref}
				disabled={disabled}
				{...props}
				$inlineSize="fit-content"
			>
				<OutlinedBox disabled={disabled} {...boxProps}>
					{children}
				</OutlinedBox>
			</ButtonBlock>
		);
	},
);

export type OutlinedBoxButtonProps = React.ComponentPropsWithRef<
	typeof OutlinedBoxButton
>;
